import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { comma } from "../components/helpers"

export default function KeyFindings({town}) {

  const q = useStaticQuery(graphql`
    {
      allMatrixCsv {
        nodes {
          Geography
          HousingUnits
          
          HousingUnitsRenterOccupied

          HousingUnitsSize2
          HousingUnitsSize3to4
          HousingUnitsSize5to9
          HousingUnitsSize10to19
          HousingUnitsSize20to49
          HousingUnitsSize50OrMore

          HousingUnitsRenterOccupied
          HousingUnitsOwnerOccupiedWithMortgage
          HousingUnitsOwnerOccupiedWithoutMortgage

          OwnerCostsToIncomeWithoutMortgage30to35
          OwnerCostsToIncomeWithoutMortgage35to40
          OwnerCostsToIncomeWithoutMortgage40to50
          OwnerCostsToIncomeWithoutMortgage50OrMore

          OwnerCostsToIncomeWithMortgage30to35
          OwnerCostsToIncomeWithMortgage35to40
          OwnerCostsToIncomeWithMortgage40to50
          OwnerCostsToIncomeWithMortgage50OrMore

          GrossRentToIncome30to35
          GrossRentToIncome35to40
          GrossRentToIncome40to50
          GrossRentToIncome50OrMore

          MedianAge
        }
      }
      allHousingWageCsv {
        nodes {
          Geography
          HMFA
          HousingWage
        }
      }
      allAppealsCsv {
        nodes {
          Geography
          PercentAssisted
          TotalAssisted
        }
      }
    }
  `)

  const matrix = q.allMatrixCsv.nodes.filter(o => o.Geography === town)[0]
  const housingWage = q.allHousingWageCsv.nodes.filter(o => o.Geography === town)[0]
  const affordable = q.allAppealsCsv.nodes.filter(o => o.Geography === town)[0]
  
  const block = function(title, value) {
    return <div className="w-50 w-third-l">
      <h4 className="f4 mv1">{title}</h4>
      <p className="f4 pl2 mt0">
        {value}
      </p>
    </div>
  }

  const valueAffordable = <span>
    { affordable.PercentAssisted }%
    ({ comma(affordable.TotalAssisted) } units)
  </span>

  const valueWage = <span>
    ${ parseFloat(housingWage.HousingWage).toFixed(2) }
  </span>

  const valueMulti = Math.round(
    (parseInt(matrix.HousingUnitsSize2)
    + parseInt(matrix.HousingUnitsSize3to4)
    + parseInt(matrix.HousingUnitsSize5to9)
    + parseInt(matrix.HousingUnitsSize10to19)
    + parseInt(matrix.HousingUnitsSize20to49)
    + parseInt(matrix.HousingUnitsSize50OrMore)) / matrix.HousingUnits * 100
  )

  const valueBurdenedRenter = Math.round(
    (parseInt(matrix.GrossRentToIncome30to35)
    + parseInt(matrix.GrossRentToIncome35to40)
    + parseInt(matrix.GrossRentToIncome40to50)
    + parseInt(matrix.GrossRentToIncome50OrMore)) / matrix.HousingUnitsRenterOccupied * 100
  )

  const valueBurdenedMortgage = Math.round(
    (parseInt(matrix.OwnerCostsToIncomeWithMortgage30to35)
    + parseInt(matrix.OwnerCostsToIncomeWithMortgage35to40)
    + parseInt(matrix.OwnerCostsToIncomeWithMortgage40to50)
    + parseInt(matrix.OwnerCostsToIncomeWithMortgage50OrMore)) / matrix.HousingUnitsOwnerOccupiedWithMortgage * 100
  )

  const valueBurdenedNoMortgage = Math.round(
    (parseInt(matrix.OwnerCostsToIncomeWithoutMortgage30to35)
    + parseInt(matrix.OwnerCostsToIncomeWithoutMortgage35to40)
    + parseInt(matrix.OwnerCostsToIncomeWithoutMortgage40to50)
    + parseInt(matrix.OwnerCostsToIncomeWithoutMortgage50OrMore)) / matrix.HousingUnitsOwnerOccupiedWithoutMortgage * 100
  )

  const valueCostBurdened = <span>
    <span className="f5">Renters:</span> {valueBurdenedRenter}%<br />
    <span className="f5">Owners (with mortgage):</span> {valueBurdenedMortgage}%<br />
    <span className="f5">Owners (no mortgage):</span> {valueBurdenedNoMortgage}%
  </span>

  return <div className="cf flex flex-wrap">

    { block('Cost-Burdened Households', valueCostBurdened)}
    { block('Housing Wage', valueWage ) }
    { block('Affordable Housing', valueAffordable ) }
    { block('Median Age', matrix.MedianAge ) }

    { block('Multifamily Housing', valueMulti + '%') }

    { block('Renters', (matrix.HousingUnitsRenterOccupied / matrix.HousingUnits * 100).toFixed(0) + '%' ) }

  </div>
}