import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { comma } from "./helpers"
import { Line } from "react-chartjs-2"
import _ from "lodash"
import "chartjs-plugin-datalabels"
import Source from "./source"

export default function PopulationLine({geos}) {

  const q = useStaticQuery(graphql`
    {
      allPopulation19962020Csv {
        nodes {
          Geography
          _2000
          _2001
          _2002
          _2003
          _2004
          _2005
          _2006
          _2007
          _2008
          _2009
          _2010
          _2011
          _2012
          _2013
          _2014
          _2015
          _2016
          _2017
          _2018
          _2019
          _2020
        }
      }
    }
  `)

  // Get data for geographies only, convert income to integers
  const dataArray = q.allPopulation19962020Csv.nodes

  const data = _.keyBy(dataArray, 'Geography')

  const years = _.range(2000, 2021)
  const values = years.map(function(year) { return data[geos[0]]['_' + year] })

  const change = data[geos[0]]._2020 - data[geos[0]]._2000

  const changeCounty = data[geos[1]]._2020 - data[geos[1]]._2000
  const changeState = data[geos[2]]._2020 - data[geos[2]]._2000

  return <div className="cf">

    <p className="measure-wide mb4">
      Between 2000 and 2020, {geos[0]}'s population {change > 0 ? 'increased' : 'decreased'} by {comma(change)} people,
      or {(change/data[geos[0]]._2000 * 100).toFixed(1)}%. At the same time, the population of
      {} {geos[1]} { changeCounty > 0 ? 'increased' : 'decreased' } by {(changeCounty / data[geos[1]]._2000 * 100).toFixed(1)}%,
      and Connecticut's population { changeState > 0 ? 'increased' : 'decreased' } by {(changeState / data[geos[2]]._2000 * 100).toFixed(1)}%.
    </p>


    <div className="relative">

      <Line
        height={250}
        data={{
          labels: years,
          datasets: [{
            label: 'Population in ' + geos[0],
            borderColor: 'rgba(159,53,21, 1)',
            backgroundColor: 'rgba(159,53,21, 0.5)',
            fill: false,
            data: values
          }]
        }}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              display: true,
              gridLines: {display: true, lineWidth: 1, zeroLineWidth: 1, zeroLineColor: 'rgba(0,0,0,0.1)'},
              ticks: {display: true, callback: function(x) {return comma(x)}, beginAtZero: false},
            }],
            xAxes: [{
              display: true,
              gridLines: {display: false}
            }]
          },
          legend: {
            display: true,
          },
          tooltips: {
            callbacks: {
              label: function(item, tooltipData) {
                return comma(item.value) + ' people'
              }
            }
          },
          
          plugins: {
            datalabels: {
              display: false
            }
          }
        }}
      />

    </div>

    <div className="cf"></div>

    <Source ctdata>
      <a href="https://portal.ct.gov/DPH/Health-Information-Systems--Reporting/Population/Annual-Town-and-County-Population-for-Connecticut" className="link silver underline dim">
        CT Department of Public Health, annual population estimates
      </a>
    </Source>

  </div>

}