import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import _ from "lodash"
import Source from "../components/source"

export default function HousingWage({geos}) {

  const dataArray = useStaticQuery(graphql`
    {
      allHousingWageCsv {
        nodes {
          Geography
          HMFA
          HousingWage
        }
      }
    }
  `)
  
  const data = _.keyBy(dataArray.allHousingWageCsv.nodes, 'Geography')

  const townHousingWage = parseFloat(data[geos[0]].HousingWage)
  const ctHousingWage = parseFloat(data['Connecticut'].HousingWage)
  const diff = parseInt((townHousingWage / ctHousingWage) * 100 - 100.0)

  return <div className="cf">
    
    <div className="w-50-ns fl pr3">
      <p>
        Each year, the National Low Income Housing Coalition calculates
        the "housing wage," the hourly wage needed to afford a two-bedroom
        rental home without paying more than 30% of income on housing.
      </p>

      <p>
        {geos[0]} is included in the {data[geos[0]].HMFA.split(' HMFA')[0]} Metro Area.
      </p>
    </div>

    <div className="w-50-ns fl pa3 bg-near-white">
      <p className="mb1 measure-narrow">
        The housing wage in {geos[0]} is
      </p>
      <h4 className="f1 ma0" style={{color: 'rgb(141,164,174)'}}>${ townHousingWage.toFixed(2) }</h4>
      <p className="mt1 measure-narrow">
        It is {Math.abs(diff) || ' similar to '}
        {diff < 0 ? '% lower than' : diff === 0 ? '' : '% higher than'} the housing wage in Connecticut (${ctHousingWage.toFixed(2)}).
      </p>

      <Source>
        2022 National Low Income Housing Coalition, OOR,  https://nlihc.org/
      </Source>

    </div>

  </div>
}